.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cSkill{
  width: 200px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Annapurna_SIL */
@font-face {
  font-family: 'Annapurna_SIL';
  src: local('Annapurna_SIL'), url(../src/Fonts/Annapurna_SIL/AnnapurnaSIL-Regular.ttf) format('truetype');
}

/* Fira_Code */
@font-face {
  font-family: 'Fira_Code';
  src: local('Fira_Code'), url(../src/Fonts/Fira_Code/static/FiraCode-Regular.ttf) format('truetype');
}

/* Jersey_25 */
@font-face {
  font-family: 'Jersey_25';
  src: local('Jersey_25'), url(../src/Fonts/Jersey_25/Jersey25-Regular.ttf) format('truetype');
}

/* Libre_Barcode_128 */
@font-face {
  font-family: 'Libre_Barcode_128';
  src: local('Libre_Barcode_128'), url(../src/Fonts/Libre_Barcode_128/LibreBarcode128-Regular.ttf) format('truetype');
}

/* Rubik_Doodle_Shadow */
@font-face {
  font-family: 'Rubik_Doodle_Shadow';
  src: local('Rubik_Doodle_Shadow'), url(../src/Fonts/Rubik_Doodle_Shadow/RubikDoodleShadow-Regular.ttf) format('truetype');
}

/* slick button color */

.slick-prev:before,
.slick-next:before {
  color: black !important;
}